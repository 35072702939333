import { Button } from "@/components/custom/button";
import MainSidebar from "@/components/custom/sidebar";
import { loadBeefreePlugin } from "@/integrations/beefree/beefree.module";
import { IBeefreeSaving } from "@/integrations/beefree/beefree.type";
import { deleteBeefreeTemplate } from "@/services/beefree";
import { useContextStore } from "@/zustand/context";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function ContentTemplatePage() {
  const { templateId, templateType } = useParams<string>();
  const [isSaving, setSaving] = useState<IBeefreeSaving>(IBeefreeSaving.NOT_STARTED);
  const [isDeleting, setDeleting] = useState<IBeefreeSaving>(IBeefreeSaving.NOT_STARTED);
  const { currentBeefreeTemplate, setCurrentBeefreeTemplate } = useContextStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (templateType === 'create') {
      loadBeefreePlugin();
    } else {
      loadBeefreePlugin(templateId);
    }
    window.onBeefreeTemplateSaved = setSaving;
    window.onBeefreeTemplateDeleted = setDeleting;
  }, [templateId]);

  useEffect(() => {
    if (isSaving === IBeefreeSaving.COMPLETED) {
      setSaving(IBeefreeSaving.NOT_STARTED);
      alert("Saved successfully!");
    } else if (isSaving === IBeefreeSaving.ERROR) {
      setSaving(IBeefreeSaving.NOT_STARTED);
      alert("Failed to save template!");
    }
  }, [isSaving]);

  useEffect(() => {
    if (isDeleting === IBeefreeSaving.COMPLETED) {
      setDeleting(IBeefreeSaving.NOT_STARTED);
      navigate('/content');
      alert("Deleted successfully!");
    } else if (isDeleting === IBeefreeSaving.ERROR) {
      setDeleting(IBeefreeSaving.NOT_STARTED);
      alert("Failed to delete template!");
    }
  }, [isDeleting]);

  const onSaveTemplate = async () => {
    if (currentBeefreeTemplate && !currentBeefreeTemplate?.templateId) {
      setCurrentBeefreeTemplate({
        ...currentBeefreeTemplate,
        templateId: `new-template-${new Date().getTime()}`
      });
    }
    window.beePlugin.saveAsTemplate();
  };

  const onDeleteTemplate = async () => {
    if (window.confirm('Are you sure that you want to delete the template?')) {
      if (!currentBeefreeTemplate?.templateId) {
        navigate('/content');
      } else {
        deleteBeefreeTemplate(currentBeefreeTemplate.templateId);
      }
    }
  };

  const isTemplateProcessing = () => {
    return isSaving === IBeefreeSaving.STARTED || isDeleting === IBeefreeSaving.STARTED;
  };

  return (
    <main>
      <MainSidebar
        toolbar={
          <div className="flex gap-4">
            <Button color="dark" onClick={onSaveTemplate} disabled={isTemplateProcessing()}>
              Save
            </Button>
            <Button color="dark" onClick={onDeleteTemplate} disabled={isTemplateProcessing()}>
              Delete
            </Button>
          </div>
        }
        shouldHideTab={true}
        pageTitle={currentBeefreeTemplate?.templateId || 'New template'}
      >
        {!currentBeefreeTemplate && (
          <div className="p-4">Loading...</div>
        )}
        <div id="bee-plugin-container" className="w-full h-full">
        </div>
      </MainSidebar>
    </main>
  );
}
