import { Avatar } from "@/components/cataylst/avatar";
import { Badge } from "@/components/cataylst/badge";
import { Input, InputGroup } from "@/components/cataylst/input";
import { Link } from "@/components/cataylst/link";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/cataylst/table";
import { Button } from "@/components/custom/button";
import { SearchIcon } from "@/components/custom/icons";
import { MenuIcon } from "@/components/custom/menu-icon";
import MainSidebar from "@/components/custom/sidebar";
import { IBeefreeTemplate } from "@/integrations/beefree/beefree.type";
import { getBeefreeTemplates } from "@/services/beefree";
import { getRelativeDate } from "@/utils/format";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function ContentPage() {
  const [templates, setTemplates] = useState<IBeefreeTemplate[]>([]);
  const { templateType } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    getBeefreeTemplates()
      .then(res => {
        setTemplates(res.data.data);
      })
  }, []);

  const onNewTemplate = () => {
    navigate('/content/create/new');
  };

  const filterTemplates = () => {
    if (!templateType)
      return templates;
    const typeMap = {
      'emails': 'email',
      'landing-pages': 'page',
      'text-messages': 'pureText',
      'popups': 'popup'
    };
    return templates.filter(item => item.type === typeMap[templateType]);
  };

  return (
    <main>
      <MainSidebar
        toolbar={
          <Button color="dark" onClick={onNewTemplate}>
            New Template
          </Button>
        }
      >
      <div className="text-cell p-8">
          <div className="mb-6 flex justify-between">
            <div className="flex gap-2">
              <div className="w-[250px]">
                <InputGroup>
                  <MenuIcon src={SearchIcon} data-slot="icon" className="!size-4 pb-2" />
                  <Input placeholder="Search templates" className="h-9 pl-9 pr-2 !rounded-full" />
                </InputGroup>
              </div>
            </div>
          </div>
          <Table grid>
            <TableHead>
              <TableRow>
                <TableHeader>Template</TableHeader>
                <TableHeader>Type</TableHeader>
                <TableHeader>Status</TableHeader>
                <TableHeader>Creator</TableHeader>
                <TableHeader>Last Modified</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterTemplates().map((template) => (
                <TableRow key={template.templateId}>
                  <TableCell className="font-semibold">
                    <Link href={`/content/email/${template.templateId}`}>{template.templateId}</Link>
                  </TableCell>
                  <TableCell>{template.type}</TableCell>
                  <TableCell>
                    <Badge color="blue">Active</Badge>
                  </TableCell>
                  <TableCell>
                    <div className="flex gap-2 items-center">
                      <Avatar square={false} src={'https://s3-alpha-sig.figma.com/img/8252/dff0/74bbf2ed399496a6b6867770170d23e9?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=giWzHfvKYceghl8Tc56GVsJ11zkeU8pcMeMGmhFGQUnbcaFY1uoR-wYebbruMzQ0wDp~TV~Dip9RCnZpHI~IlAgiM-svICWSUpV-9fiCa93aQ53xHQmKDDFHr8pvRxP1V25BIRhZ15avIIhQOVft2h5OEsDy8MuucQt2K3aK~3zsgk1uAZ4P5AoiU76HlcU9jm0glwg2lwBHft9Msj1fSOhttFxV1hnfiS7ksSSFKcMtuw1V1KHTzQ7TDCet4daMt0g4DfxASooFv7dgeCR6nkDAa9WGJpO9Mfs6sBWbhutZLNd2HrQlENRBlobvq840q4-p7ewrIQwOXE8pzoeNtg__'} className="size-4" />
                      <span>Brooklyn Simmons</span>
                    </div>
                  </TableCell>
                  <TableCell>{getRelativeDate(new Date(template.lastUpdate))}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </MainSidebar>
    </main>
  )
}