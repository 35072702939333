import { FC, HTMLAttributes, PropsWithChildren } from "react"

interface IMenuIconProps extends HTMLAttributes<HTMLElement> {
  src: string;
  opacity?: number;
};

export const MenuIcon: FC<PropsWithChildren<IMenuIconProps>> = ({ children, className, src, opacity, ...props })  => {
  return (
    <div className={`${className} !size-6 flex items-center justify-center`} {...props}>
      <img src={src} style={{ filter: `opacity(${opacity})` }} />
    </div>
  )
}