import { Avatar } from "@/components/cataylst/avatar";
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "@/components/cataylst/dropdown";
import {
  Sidebar,
  SidebarBody,
  SidebarDivider,
  SidebarFooter,
  SidebarHeader,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
} from "@/components/cataylst/sidebar";
import { SidebarLayout } from "@/components/cataylst/sidebar-layout";
import {
  ArrowRightStartOnRectangleIcon,
  Cog8ToothIcon,
  LightBulbIcon,
  PlusIcon,
  ShieldCheckIcon,
  UserIcon,
  EllipsisHorizontalIcon,
  InboxIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/16/solid";
import clsx from "clsx";
import { FC, PropsWithChildren, ReactNode, useState } from "react";
import { Button } from "./button";
import { CircularButton } from "./circular-button";
import { CloseLeftIcon, CloseRightIcon, LogoIcon } from "./icons";
import { MenuIcon } from "./menu-icon";
import {
  bloomMenuItems,
  getCurrentSubMenuItems,
  getPageTitle,
  IMenuItem,
} from "@/utils/constants";
import { useLocation } from "react-router-dom";
import { BloomStorage } from "@/utils/storage";
import MainDrawer from "./drawer";
import {
  Navbar,
  NavbarItem,
  NavbarSection,
  NavbarSpacer,
} from "../cataylst/navbar";
import { useContextStore } from "@/zustand/context";

interface IMainSidebarProps {
  toolbar?: ReactNode;
  shouldHideTab?: boolean;
  pageTitle?: string;
}

const MainSidebar: FC<PropsWithChildren<IMainSidebarProps>> = ({
  children,
  toolbar,
  pageTitle,
  shouldHideTab
}) => {
  console.log({ pageTitle });
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(
    BloomStorage.isSidebarOpen()
  );
  const { pathname } = useLocation();
  const { currentUser } = useContextStore();

  const onToggleSidebar = () => {
    setSidebarOpen((isSidebarOpen) => !isSidebarOpen);
    BloomStorage.setSidebarOpen(!isSidebarOpen);
  };

  const renderMenuItem = (menuItem: IMenuItem) => {
    if (!menuItem.slug) {
      return (
        <SidebarDivider className={`${isSidebarOpen ? "mx-[10px]" : "mx-0"}`} />
      );
    }
    return (
      <SidebarItem
        href={menuItem.slug}
        current={pathname.startsWith(menuItem.slug)}
      >
        <MenuIcon src={menuItem.icon!} opacity={0.6} />
        {isSidebarOpen && <SidebarLabel>{menuItem.label}</SidebarLabel>}
      </SidebarItem>
    );
  };

  const tabs = getCurrentSubMenuItems(pathname);

  return (
    <SidebarLayout
      isSidebarOpen={isSidebarOpen}
      navbar={
        <Navbar>
          <NavbarSpacer />
          <NavbarSection>
            <NavbarItem href="/search" aria-label="Search">
              <MagnifyingGlassIcon />
            </NavbarItem>
            <NavbarItem href="/inbox" aria-label="Inbox">
              <InboxIcon />
            </NavbarItem>
            <Dropdown>
              <DropdownButton as={NavbarItem}>
                <Avatar
                  src="https://catalyst.tailwindui.com/profile-photo.jpg"
                  square
                />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="bottom end">
                <DropdownItem href="/my-profile">
                  <UserIcon />
                  <DropdownLabel>My profile</DropdownLabel>
                </DropdownItem>
                <DropdownItem href="/settings">
                  <Cog8ToothIcon />
                  <DropdownLabel>Settings</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem href="/privacy-policy">
                  <ShieldCheckIcon />
                  <DropdownLabel>Privacy policy</DropdownLabel>
                </DropdownItem>
                <DropdownItem href="/share-feedback">
                  <LightBulbIcon />
                  <DropdownLabel>Share feedback</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem href="/logout">
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavbarSection>
        </Navbar>
      }
      sidebar={
        <Sidebar>
          <SidebarHeader>
            <div className="flex gap-2 items-center lg:mb-[21px]">
              <Avatar src={LogoIcon} square={true} outline={false} />
              <SidebarLabel className="text-title">Bloom</SidebarLabel>
            </div>
          </SidebarHeader>
          <SidebarBody>
            <SidebarSection className="max-lg:hidden">
              {bloomMenuItems.map((menuItem, index) => (
                <div key={index}>{renderMenuItem(menuItem)}</div>
              ))}
            </SidebarSection>
          </SidebarBody>
          <SidebarFooter className="max-lg:hidden">
            <SidebarSection>
              <SidebarItem onClick={onToggleSidebar}>
                <MenuIcon src={isSidebarOpen ? CloseLeftIcon : CloseRightIcon} opacity={0.6} />
                {isSidebarOpen && <SidebarLabel>Close</SidebarLabel>}
              </SidebarItem>
              <Dropdown>
                <DropdownButton as={SidebarItem}>
                  <span className="flex min-w-0 items-center gap-2">
                    <Avatar
                      src={currentUser.picture}
                      className="size-6"
                      square={false}
                      alt=""
                    />
                    {isSidebarOpen && (
                      <span className="min-w-0">
                        <span className="block truncate text-menu">
                          {currentUser.name}
                        </span>
                      </span>
                    )}
                  </span>
                </DropdownButton>
                <DropdownMenu className="min-w-64" anchor="top start">
                  <DropdownItem href="/my-profile">
                    <UserIcon className="!size-6" />
                    <DropdownLabel>My profile</DropdownLabel>
                  </DropdownItem>
                  <DropdownItem href="/settings">
                    <Cog8ToothIcon className="!size-6" />
                    <DropdownLabel>Settings</DropdownLabel>
                  </DropdownItem>
                  <DropdownDivider />
                  <DropdownItem href="/privacy-policy">
                    <ShieldCheckIcon className="!size-6" />
                    <DropdownLabel>Privacy policy</DropdownLabel>
                  </DropdownItem>
                  <DropdownItem href="/share-feedback">
                    <LightBulbIcon className="!size-6" />
                    <DropdownLabel>Share feedback</DropdownLabel>
                  </DropdownItem>
                  <DropdownDivider />
                  <DropdownItem href="/logout">
                    <ArrowRightStartOnRectangleIcon className="!size-6" />
                    <DropdownLabel>Sign out</DropdownLabel>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </SidebarSection>
          </SidebarFooter>
        </Sidebar>
      }
    >
      {/* The page content */}
      <div className="flex flex-col h-full">
        <div className="px-8 bg-white">
          <div className="flex justify-between h-[64px] items-center">
            <div className="text-title">{pageTitle ?? getPageTitle(pathname)}</div>
            <div className="flex gap-2 items-center">
              {toolbar}
              <CircularButton onClick={() => setDrawerOpen(true)}>
                <EllipsisHorizontalIcon height={16} />
              </CircularButton>
            </div>
          </div>
          {tabs && !shouldHideTab && (
            <div className="border-t border-bloom-grey-line h-[48px]">
              <nav aria-label="Tabs" className="-mb-px flex space-x-8">
                {tabs.map((tab, index) => (
                  <a
                    key={tab.label + index}
                    href={tab.slug}
                    className={clsx(
                      pathname === tab.slug
                        ? "border-black text-black"
                        : "border-transparent text-bloom-grey-menu",
                      "whitespace-nowrap border-t-2 py-[15px] text-menu"
                    )}
                  >
                    {tab.label}
                  </a>
                ))}
              </nav>
            </div>
          )}
        </div>
        <div className="mt-1 flex-1 bg-white">{children}</div>
      </div>
      <MainDrawer isDrawerOpen={isDrawerOpen} setDrawerOpen={setDrawerOpen} />
    </SidebarLayout>
  );
};

export default MainSidebar;
