import { ChannelIcon, ChartIcon, ContentIcon, CustomersIcon, DashboardIcon, KnowledgeIcon, ThunderIcon, ToggleIcon } from "@/components/custom/icons";

export interface IMenuItem {
  label: string;
  icon?: string;
  slug?: string;
  menuItems?: IMenuItem[];
};

export const bloomMenuItems: IMenuItem[] = [
  {
    label: 'Dashboard',
    slug: '/dashboard',
    icon: DashboardIcon
  },
  {
    label: 'Customers',
    slug: '/customers',
    icon: CustomersIcon,
    menuItems: [
      {
        label: 'Companies',
        slug: '/customers'
      },
      {
        label: 'Audience',
        slug: '/customers/audience'
      },
      {
        label: 'Contacts',
        slug: '/customers/contacts'
      },
      {
        label: 'Segments',
        slug: '/customers/segments'
      }
    ]
  },
  {
    label: 'Actions',
    slug: '/actions',
    icon: ThunderIcon
  },
  {
    label: 'Performance',
    slug: '/performance',
    icon: ChartIcon
  },
  {
    label: 'Horizontal1'
  },
  {
    label: 'Sources',
    slug: '/sources',
    icon: ToggleIcon
  },
  {
    label: 'Channels',
    slug: '/channels',
    icon: ChannelIcon
  },
  {
    label: 'Knowledge Base',
    slug: '/knowledge-base',
    icon: KnowledgeIcon
  },
  {
    label: 'Content',
    slug: '/content',
    icon: ContentIcon,
    menuItems: [
      {
        label: 'All',
        slug: '/content'
      },
      {
        label: 'Emails',
        slug: '/content/emails'
      },
      {
        label: 'Landing Pages',
        slug: '/content/landing-pages'
      },
      {
        label: 'Text Messages',
        slug: '/content/text-messages'
      }
    ]
  },
];

export const getCurrentSubMenuItems = (pathname: string) => {
  return bloomMenuItems.find(menuItem => menuItem.slug && pathname.includes(menuItem.slug || ''))?.menuItems;
};

export const getPageTitle = (pathname: string) => {
  return bloomMenuItems.find(menuItem => menuItem.slug && pathname.includes(menuItem.slug || ''))?.label;
};

export const Environment = {
  API_URL: import.meta.env.VITE_API_URL,
  WS_API_URL: import.meta.env.VITE_WS_API_URL,
  AUTH0_DOMAIN: import.meta.env.VITE_AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: import.meta.env.VITE_AUTH0_CLIENT_ID,
  AGENT_ID: '7bc2d39d-5c4e-4134-b763-910626230c54',
  CONVERSATION_ID: 'f2f72b5b-7607-49d2-bac2-558bec378943',
  BEE_PLUGIN_CLIENT_ID: import.meta.env.VITE_BEE_PLUGIN_CLIENT_ID,
  BEE_PLUGIN_CLIENT_SECRET: import.meta.env.VITE_BEE_PLUGIN_CLIENT_SECRET,  
};
