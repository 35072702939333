import { Checkbox } from "@/components/cataylst/checkbox";
import { Input, InputGroup } from "@/components/cataylst/input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/cataylst/table";
import { Button } from "@/components/custom/button";
import { AIStarIcon, KanbanIcon, SearchIcon } from "@/components/custom/icons";
import { MenuIcon } from "@/components/custom/menu-icon";
import MainSidebar from "@/components/custom/sidebar";
import { getAudienceList } from "@/services/audience";
import { formatDate } from "@/utils/format";
import { AdjustmentsHorizontalIcon, ListBulletIcon, ViewColumnsIcon } from "@heroicons/react/16/solid";
import { useEffect, useState } from "react";

export default function CustomerAudiencePage() {
  const [audience, setAudience] = useState<any[]>([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  useEffect(() => {
    getAudienceList()
      .then(res => {
        setAudience(res.data.data);
      })
  }, []);

  const onSearchAudience = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
  };

  const filterUsers = (keyword: string) => {
    return audience.filter(user => user.name.toLowerCase().includes(keyword.toLowerCase()));
  };

  return (
    <main>
      <MainSidebar>
        <div className="text-cell p-8">
          <div className="mb-6 flex justify-between">
            <div className="w-[250px]">
              <InputGroup>
                <MenuIcon src={AIStarIcon} data-slot="icon" className="!size-4 pb-2" />
                <Input placeholder="Ask Bloom AI..." className="h-9 pl-9 pr-2 !rounded-full" />
              </InputGroup>
            </div>
            <div className="flex gap-2">
              <div className="w-[250px]">
                <InputGroup>
                  <MenuIcon src={SearchIcon} data-slot="icon" className="!size-4 pb-2" />
                  <Input placeholder="Search audience" className="h-9 pl-9 pr-2 !rounded-full" onChange={onSearchAudience} />
                </InputGroup>
              </div>
              <Button color="white">
                <AdjustmentsHorizontalIcon color="black" height={16} />
                Filter
              </Button>
            </div>
            <div className="flex gap-2">
              <Button color="white">
                <ListBulletIcon color="black" height={16} />
                List
              </Button>
              <Button color="white" disabled={true}>
                <MenuIcon className="!size-4" src={KanbanIcon} data-slot="icon" />
                Kanban
              </Button>
            </div>
          </div>
          <Table grid>
            <TableHead>
              <TableRow>
                <TableHeader>
                  <div className="flex gap-4 items-center">
                    <Checkbox />
                    <span>Name</span>
                  </div>
                </TableHeader>
                <TableHeader>Email</TableHeader>
                <TableHeader>Registered At</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterUsers(searchKeyword).map((user) => (
                <TableRow key={user.userId}>
                  <TableCell>
                    <div className="flex gap-4 items-center">
                      <Checkbox />
                      <div className="flex gap-2 items-center">
                        <span>{user.name}</span>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex gap-2 items-center">
                      <span>{user.userId}</span>
                    </div>
                  </TableCell>
                  <TableCell>
                    {formatDate(new Date(user.indexedAt))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </MainSidebar>
    </main>
  )
}