import { ButtonHTMLAttributes, FC, PropsWithChildren } from "react";
import { Link } from "react-router-dom";

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  url?: string;
  className?: string;
  color?: 'dark' | 'white';
};

export const Button: FC<PropsWithChildren<IButtonProps>> = ({ children, url, className, color, ...props })  => {
  const button = (
    <button className={`${className} flex gap-2 items-center text-button justify-center h-[36px] ${color === 'dark' ? 'text-white bg-black border-black' : 'text-black bg-white border-gray-300'} rounded-full pl-[16px] pr-[20px] py-[10px] font-firstext outline-none focus:outline-none z-[100] border ${props.disabled ? 'opacity-60' : ''}
    `} {...props}>
      {children}
    </button>
  );

  if (url) {
    return (
      <Link to={url}>
        {button}
      </Link>
    );
  }

  return <>{button}</>;
}