import { Routes, Route } from 'react-router-dom';
import DashboardPage from '@/pages/DashboardPage';
import CustomerCompaniesPage from '@/pages/CustomerCompaniesPage';
import HomePage from '@/pages/HomePage';
import ContentPage from '@/pages/ContentPage';
import ContentTemplatePage from '@/pages/ContentTemplatePage';
import CustomerAudiencePage from '@/pages/CustomerAudiencePage';
import LogoutPage from '@/pages/LogoutPage';

export default function Router() {
  return (
    <Routes>
      <Route path='/' element={<HomePage />} />
      <Route path='/dashboard' element={<DashboardPage />} />
      <Route path='/customers' element={<CustomerCompaniesPage />} />
      <Route path='/customers/audience' element={<CustomerAudiencePage />} />
      <Route path='/content' element={<ContentPage />} />
      <Route path='/content/:templateType' element={<ContentPage />} />
      <Route path='/content/:templateType/:templateId' element={<ContentTemplatePage />} />
      <Route path='/logout' element={<LogoutPage />} />
      <Route path='*' element={<DashboardPage />} />
      {/* <Route path="/not-found" element={<Error404Page />} />
      <Route path="*" element={<Navigate to='/not-found' replace />} /> */}
    </Routes>
  );
}