import { Avatar } from "@/components/cataylst/avatar";
import { Badge } from "@/components/cataylst/badge";
import { Checkbox } from "@/components/cataylst/checkbox";
import { Input, InputGroup } from "@/components/cataylst/input";
import { Link } from "@/components/cataylst/link";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/cataylst/table";
import { Button } from "@/components/custom/button";
import { AIStarIcon, KanbanIcon, SearchIcon } from "@/components/custom/icons";
import { MenuIcon } from "@/components/custom/menu-icon";
import MainSidebar from "@/components/custom/sidebar";
import { getAudienceList } from "@/services/audience";
import { formatCurrency, formatDate } from "@/utils/format";
import { AdjustmentsHorizontalIcon, ListBulletIcon, ViewColumnsIcon } from "@heroicons/react/16/solid";
import { useEffect, useState } from "react";

const companies = [
  { id: 0, name: 'Meta', logo: 'https://s3-alpha-sig.figma.com/img/455d/6a6a/db4842e1e1bf0aa16a7273e9a08282bc?Expires=1724025600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ns1NO-WjSEpvswCtO7uLZHCCU~lgPVNZzmnXUdPQePA5x4qbdD3n9vcvkGinaTdIVV262M3Lw7XWb0211NdbXiR8wTLsx6y5z4eX9gYY39AgrJBcw41c3C8x6NTqLPT~x5WEAxoW5r41zFbIEo1R9aKs8DNgXywUPQOPlGqrdgPXyJEOiGgjJ9tP31us425ha-Nc2iVnr6w1lL-UnHSkOUn5t8pGfDCfKt2kcJ4s8f5zxW6MjUJT0Fg4qiPYhmFFRQfM-Qi44LudJyUyH-u8jlf90ZPJSHzbZjmWOkf7U-cIgoeynu70PwepNjkji4k5dfo9nOmAPA2tmsojUBYjbw__', contact: { name: 'Sarah Hughes', avatar: 'https://s3-alpha-sig.figma.com/img/21a8/1a5d/81e415238a036e2f11f72764f1758688?Expires=1724025600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ODpzd6Y4aP-sTnNlafx-IAsfH9x2stOYOg2JpRafyDP5Hsy0sQDifM7dCBG9RoSGJX8mpb7Ed9G-ID2QkqdqAoQWNcZvkwcyxiq7352i3PVhFU7VcEA2tnna9DOcwQutIYB-Su3I8BHCRXrlRFQVJ26mNX1MU-kPwl7uXHgdtooIXtYKTBsWu8YEM32P~ruy2af~cZos3UTMHpiuhI-SoFL-Z1C7DP-4LWvhrHBySq8nvMJqxwOf1Yvacew1p0Ae2XgsapHG0aP62FioX0bNlDEv-sKwuG01qNBJZpe4ZLG2H8ypZk07g8~6f6j3AgX2n7cUXDbHWVNCPYZok8xS-A__' }, dealStage: 'Solution Evaluation', linkedin: { url: 'https://linkedin.com/company/meta', link: 'meta' }, dealSize: { currency: 'USD', amount: 3500000 }, expectedClose: new Date(2024, 5, 25), compensation: { currency: 'USD', amount: 1200000 } }
];

export default function CustomerCompaniesPage() {
  const [audience, setAudience] = useState<any[]>([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  useEffect(() => {
    getAudienceList()
      .then(res => {
        setAudience(res.data.data);
      })
  }, []);
  const company = companies[0];

  const onSearchCompanies = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
  };

  const filterUsers = (keyword: string) => {
    return audience.filter(user => user.name.toLowerCase().includes(keyword.toLowerCase()));
  };

  return (
    <main>
      <MainSidebar>
        <div className="text-cell p-8">
          <div className="mb-6 flex justify-between">
            <div className="w-[250px]">
              <InputGroup>
                <MenuIcon src={AIStarIcon} data-slot="icon" className="!size-4 pb-2" />
                <Input placeholder="Ask Bloom AI..." className="h-9 pl-9 pr-2 !rounded-full" />
              </InputGroup>
            </div>
            <div className="flex gap-2">
              <div className="w-[250px]">
                <InputGroup>
                  <MenuIcon src={SearchIcon} data-slot="icon" className="!size-4 pb-2" />
                  <Input placeholder="Search companies" className="h-9 pl-9 pr-2 !rounded-full" onChange={onSearchCompanies} />
                </InputGroup>
              </div>
              <Button color="white">
                <AdjustmentsHorizontalIcon color="black" height={16} />
                Filter
              </Button>
            </div>
            <div className="flex gap-2">
              <Button color="white">
                <ListBulletIcon color="black" height={16} />
                List
              </Button>
              <Button color="white" disabled={true}>
                <MenuIcon className="!size-4" src={KanbanIcon} data-slot="icon" />
                Kanban
              </Button>
            </div>
          </div>
          <Table grid>
            <TableHead>
              <TableRow>
                <TableHeader>
                  <div className="flex gap-4 items-center">
                    <Checkbox />
                    <span>Company</span>
                  </div>
                </TableHeader>
                <TableHeader>Contact</TableHeader>
                <TableHeader>Deal Stage</TableHeader>
                <TableHeader>LinkedIn</TableHeader>
                <TableHeader>Deal Size (ACV)</TableHeader>
                <TableHeader>Expected Close</TableHeader>
                <TableHeader>Compensation</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterUsers(searchKeyword).map((user) => (
                <TableRow key={user.userId}>
                  <TableCell>
                    <div className="flex gap-4 items-center">
                      <Checkbox />
                      <div className="flex gap-2 items-center">
                        <Avatar square={false} src={company.logo} outline={false} className="size-4" />
                        <span>{company.name}</span>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex gap-2 items-center">
                      <Avatar square={false} src={company.contact.avatar} className="size-4" />
                      <span>{user.name}</span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <Badge color="blue">{company.dealStage}</Badge>
                  </TableCell>
                  <TableCell>
                    <Link className="underline" href={company.linkedin.url}>{company.linkedin.link}</Link>
                  </TableCell>
                  <TableCell>
                    {formatCurrency(company.dealSize.amount, company.dealSize.currency)}
                  </TableCell>
                  <TableCell>
                    {formatDate(company.expectedClose)}
                  </TableCell>
                  <TableCell>
                    {formatCurrency(company.compensation.amount, company.dealSize.currency)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </MainSidebar>
    </main>
  )
}