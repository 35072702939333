import { useEffect, useLayoutEffect } from "react";
import "./App.css";
import Router from "./routes/Router";
import ContextObserver from "./zustand/context-observer";

function App() {
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const timer = setInterval(handleResize, 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const handleResize = () => {
    const headerDom = document.getElementById("header");
    if (headerDom) {
      document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth + 2) + "px");
    }
  };

  return (
    <div className="font-p22">
      <ContextObserver />
      <Router />
    </div>
  );
}

export default App;
