import { Environment } from "@/utils/constants";
import { BloomStorage } from "@/utils/storage";
import axios from "axios";

export const apiClient = axios.create({
  baseURL: Environment.API_URL
});

apiClient.interceptors.request.use((config) => {
  const accessToken = BloomStorage.getAccessToken();
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});