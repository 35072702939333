import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { IconButton } from "./icon-button";
import { CloseLeftIcon, CloseRightIcon, VoiceIcon, XMarkIcon } from "./icons";
import { Input, InputGroup } from "../cataylst/input";
import { MenuIcon } from "./menu-icon";
import { useCallback, useEffect, useState } from "react";
import { useConversationStore } from "@/services/conversation";
import { useWebsocketStore } from "@/services/websocket";
import Sockette from "sockette";
import Messages from "../core/conversation/messages";
import { Environment } from "@/utils/constants";

interface IMainDrawerProps {
  isDrawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function MainDrawer({ isDrawerOpen, setDrawerOpen }: IMainDrawerProps) {
  const [drawerSize, setDrawerSize] = useState('sm');
  const { connect, disconnect, connected, subscribe, unsubscribe, socket } =
    useWebsocketStore();
  const {
    conversation,
    messages,
    cursors,
    fetchMessages,
    sendMessage,
    updateTitle,
    processWSMessage,
  } = useConversationStore();
  const conversationId = Environment.CONVERSATION_ID;

  const [newMessage, setNewMessage] = useState('');

  const handleTitleUpdate = useCallback(
    async (title: string) => {
      await updateTitle(title);
    },
    [updateTitle]
  );

  const handleMessageUpdate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewMessage(e.target.value);
    },
    []
  );

  const sendMessageHandler = useCallback(async () => {
    if (!newMessage) return;
    if (cursors.newMessage.sending) return;

    await sendMessage(newMessage, socket as Sockette);
    setNewMessage('');
  }, [cursors.newMessage.sending, newMessage, sendMessage, socket]);

  // Handler for the keypress event in the input. 'enter' should send the message
  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        sendMessageHandler();
      }
    },
    [sendMessageHandler]
  );

  useEffect(() => {
    if (!conversationId) return;

    fetchMessages(conversationId);
  }, [conversationId, fetchMessages]);

  useEffect(() => {
    if (connected) {
      subscribe(processWSMessage);
    }
    return () => {
      unsubscribe(processWSMessage);
    };
  }, [connected, processWSMessage, subscribe, unsubscribe]);

  useEffect(() => {
    connect();
    return () => {
      disconnect();
    };
  }, [connect, disconnect]);

  useEffect(() => {
    if (conversationId) {
      fetchMessages(conversationId);
    }
  }, [connected, fetchMessages, conversationId]);
  
  const onResizeDrawer = () => {
    setDrawerSize(drawerSize => drawerSize === 'sm' ? 'lg' : 'sm');
  };
  return (
    <Dialog open={isDrawerOpen} onClose={setDrawerOpen} className="relative z-10">
      <div className="fixed inset-0" />
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className={`pointer-events-auto w-screen ${drawerSize === 'sm' ? 'max-w-md' : 'max-w-6xl'} transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700`}
            >
              <div className="flex h-full flex-col overflow-y-auto bg-white pt-6 pb-3 shadow-xl">
                <div className="px-6 pb-[16px] border-b border-bloom-grey-line">
                  <div className="flex items-center justify-between">
                    <DialogTitle className="text-title">Bloom AI</DialogTitle>
                    <div className="flex gap-3">
                      <IconButton src={drawerSize === 'sm' ? CloseLeftIcon : CloseRightIcon} onClick={onResizeDrawer} />
                      <IconButton src={XMarkIcon} onClick={() => setDrawerOpen(false)} />
                    </div>
                  </div>
                </div>
                <Messages
                  messages={messages}
                  assistantName={conversation?.agentName ?? ''}
                />
                <div className="relative mt-6 flex-1 px-3 flex flex-col-reverse">
                  <div>
                    <InputGroup>
                      <Input
                        placeholder="What can I help you with?"
                        className="h-12 pl-4 pr-3"
                        disabled={
                          !connected ||
                          cursors.newMessage.sending ||
                          cursors.newMessage.receiving
                        }
                        onChange={handleMessageUpdate}
                        onKeyDown={handleKeyPress}
                      />
                      <MenuIcon src={VoiceIcon} data-slot="icon" />
                    </InputGroup>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
